import { styled } from '@compiled/react';
import type { PropsWithChildren } from 'react';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import type { CleanupFn } from '@atlaskit/pragmatic-drag-and-drop/types';

import { getClosestScrollableElement } from './get-closest-scrollable-element';
import { TreeItemBase } from './TreeItemBase';
import { TreeItemDraggable } from './TreeItemDraggable';
import type {
	DragEnabledProp,
	DragPreview,
	ItemId,
	OnDragEnd,
	OnDragStart,
	RenderItem,
	TreeItem,
	TreeObject,
	TreeSourcePosition,
	RenderItemEmptyState,
} from './tree-types';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TreeItemUl = styled.ul({
	listStyleType: 'none',
	marginLeft: '0px',
	paddingLeft: '0px',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'ul, ul:not(:first-child)': {
		marginTop: '0px',
	},
});

const noop = () => {};
export type TreeProps<TItem extends TreeItem> = {
	tree: TreeObject<TItem>;
	renderItem: RenderItem<TItem>; // TODO: in the future we could type this more strongly based on isDragEnabled
	indentPerLevel: number;
	onExpand?(itemId: ItemId): void;
	onCollapse?(itemId: ItemId): void;
	isDragEnabled?: DragEnabledProp<TItem>;
	onDragStart?: OnDragStart;
	onDragEnd?: OnDragEnd;
	dragPreview?: DragPreview<TItem>;
	itemType?: string;
	renderItemEmptyState?: RenderItemEmptyState<TItem>;
};

const Child = <TItem extends TreeItem>({
	item,
	pathToParent,
	parentId,
	index,
	parentLevel,
	isLastInGroup,
	TreeItemComponent,
	...shared
}: {
	item: TItem;
	pathToParent: TreeSourcePosition[];
	index: number;
	parentLevel: number;
	parentId: ItemId;
	isLastInGroup: boolean;
	TreeItemComponent: typeof TreeItemDraggable;
} & SharedProps<TItem>) => {
	// memoizing the `path` so that a re-render of the parent won't cause
	// the `path` reference to change if it does not need to
	const path: TreeSourcePosition[] = useMemo(
		() => [...pathToParent, { parentId, index }],
		[pathToParent, parentId, index],
	);
	return (
		<TreeItemComponent<TItem>
			key={`tree-item-${item.id}`}
			item={item}
			renderItem={shared.renderItem}
			indentPerLevel={shared.indentPerLevel}
			currentLevel={parentLevel}
			isExpanded={item.isExpanded}
			onExpand={shared.onExpand}
			onCollapse={shared.onCollapse}
			// draggable props
			index={index}
			isLastInGroup={isLastInGroup}
			path={path}
			onDragStart={shared.onDragStart ?? noop}
			onDragEnd={shared.onDragEnd ?? noop}
			dragPreview={shared.dragPreview}
			isDragEnabled={shared.isDragEnabled}
			itemType={shared.itemType ?? 'tree-item'}
		>
			{item.children.length ? (
				<Parent
					parent={item}
					currentPath={path}
					TreeItemComponent={TreeItemComponent}
					{...shared}
				/>
			) : (
				shared.renderItemEmptyState?.({
					item,
					indent: shared.indentPerLevel * parentLevel + shared.indentPerLevel + 24,
				})
			)}
		</TreeItemComponent>
	);
};

type SharedProps<TItem extends TreeItem> = PropsWithChildren<TreeProps<TItem>> & {
	TreeItemComponent: typeof TreeItemDraggable;
};

type ParentProps<TItem extends TreeItem> = {
	ref?: React.Ref<HTMLUListElement>;
	parent: TItem;
	currentPath: TreeSourcePosition[];
} & SharedProps<TItem>;

/**
 * Generics in prop types don't seem to play well with `forwardRef` so we
 * are using this to work around type errors.
 *
 * See <https://stackoverflow.com/a/73795494> for further information.
 */
interface ParentWithForwardRef extends React.FC<ParentProps<TreeItem>> {
	<TItem extends TreeItem>(props: ParentProps<TItem>): ReturnType<React.FC<ParentProps<TItem>>>;
}

const Parent: ParentWithForwardRef = forwardRef(
	<TItem extends TreeItem>(
		{
			parent,
			currentPath,
			...shared
		}: {
			parent: TItem;
			currentPath: TreeSourcePosition[];
		} & SharedProps<TItem>,
		ref: React.Ref<HTMLUListElement>,
	) => {
		// Being cautious: don't render a Parent it does not have any children
		// This guard is for safety and is also hit for empty spaces
		if (parent.children.length === 0) {
			return null;
		}

		const parentLevel = currentPath.length;

		return (
			<TreeItemUl data-vc="tree-item-ul" ref={ref} role={parentLevel === 0 ? 'tree' : 'group'}>
				{parent.children.map((childId: ItemId, index: number, array: ItemId[]) => {
					const item = shared.tree.items[childId];
					return (
						<Child
							key={`tree-item-${item.id}`}
							item={item}
							pathToParent={currentPath}
							parentLevel={parentLevel}
							parentId={parent.id}
							index={index}
							isLastInGroup={index === array.length - 1}
							{...shared}
						/>
					);
				})}
			</TreeItemUl>
		);
	},
) as ParentWithForwardRef;

export const Tree = <TItem extends TreeItem>(props: PropsWithChildren<TreeProps<TItem>>) => {
	const TreeItemComponent = useMemo(
		() => (props.isDragEnabled ? TreeItemDraggable : TreeItemBase),
		[props.isDragEnabled],
	);

	// using a stable reference for our root path so we don't break `path` memoization for children
	const [path] = useState([]);

	const ref = useRef<HTMLUListElement>(null);
	useEffect(() => {
		let cleanupAutoScroll: CleanupFn | null = null;

		return combine(
			monitorForElements({
				onDragStart: () => {
					/**
					 * This hook will setup autoscrolling on the first drag.
					 *
					 * If it has already been setup then we don't need to do anything.
					 */
					if (cleanupAutoScroll) {
						return;
					}

					/**
					 * We need to do a lookup because the scroll container is not rendered
					 * by the tree, the tree is just rendered inside of it.
					 *
					 * We do not have a reference to the scroll container passed in.
					 */
					const closestScrollable = getClosestScrollableElement(ref.current);

					if (!closestScrollable) {
						return;
					}
					cleanupAutoScroll = autoScrollForElements({
						element: closestScrollable,
						canScroll: ({ source }) => source.data.type === 'tree-item',
					});
				},
				canMonitor: ({ source }) => source.data.type === 'tree-item',
			}),
			() => cleanupAutoScroll?.(),
		);
	}, []);

	const root = props.tree.items[props.tree.rootId];

	// Cannot render a tree without a root tree item
	if (!root) {
		return null;
	}

	return (
		<Parent
			ref={ref}
			parent={root}
			currentPath={path}
			TreeItemComponent={TreeItemComponent}
			{...props}
		/>
	);
};
