import type { PropsWithChildren } from 'react';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import invariant from 'tiny-invariant';
import ReactDOM from 'react-dom';

import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import type { Instruction, ItemMode } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import {
	attachInstruction,
	extractInstruction,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/tree-item';
import {
	draggable,
	dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import type { DragLocationHistory } from '@atlaskit/pragmatic-drag-and-drop/types';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { scrollJustEnoughIntoView } from '@atlaskit/pragmatic-drag-and-drop/element/scroll-just-enough-into-view';

import { delay } from './delay';
import type { RefHandler, TreeItemBaseProps } from './TreeItemBase';
import { TreeItemBase } from './TreeItemBase';
import type {
	DragEnabledProp,
	DraggableState,
	ItemId,
	OnDragEnd,
	OnDragStart,
	DragPreview,
	TreeDestinationPosition,
	TreeSourcePosition,
	TreeItem,
} from './tree-types';

export type LocalState =
	| { type: Exclude<DraggableState, 'generate-preview'> }
	| { type: 'generate-preview'; container: HTMLElement };

const i18n = defineMessages({
	draggableItemLabel: {
		id: 'tree.draggable-item.label',
		description: 'aria-label for the draggable page tree item',
		defaultMessage: `{title} draggable item`,
	},
});

function getItemMode<TItem extends TreeItem>(isLastInGroup: boolean, item: TItem): ItemMode {
	if (item.isExpanded && item.hasChildren) {
		return 'expanded';
	} else if (isLastInGroup) {
		return 'last-in-group';
	}
	return 'standard';
}

const getParentLevelOfInstruction = (instruction: Instruction): number => {
	if (instruction.type === 'instruction-blocked') {
		return getParentLevelOfInstruction(instruction.desired);
	}
	if (instruction.type === 'reparent') {
		return instruction.desiredLevel - 1;
	}
	return instruction.currentLevel - 1;
};

export type TreeItemDraggableProps<TItem extends TreeItem> = TreeItemBaseProps<TItem> & {
	onDragStart: OnDragStart;
	onDragEnd: OnDragEnd;
	index: number;
	isLastInGroup: boolean;
	path: TreeSourcePosition[];
	itemType: string;
	dragPreview?: DragPreview<TItem>;
	isDragEnabled?: DragEnabledProp<TItem>;
};

/** Using a shared object for the 'idle'
 *
 * This is done so that when a component calls `setDraggableState(idle)`,
 * the component to re-render if it is already in the idle state.
 *
 * We _could_ create a new `idle` object per component:
 *
 * `const idle = useMemo(() => ({ type: "idle" }), []);`
 *
 * But, sharing the same `idle` object between components works well
 * and does not require any additional `useMemo` work by react.
 */
const idle: LocalState = { type: 'idle' };

const parentOfInstruction: LocalState = { type: 'parent-of-instruction' };

const TreeItemDraggableInner = <TItem extends TreeItem>({
	onDragStart,
	onDragEnd,
	dragPreview,
	index,
	isLastInGroup,
	path,
	itemType,
	isDragEnabled,
	renderItem,
	...baseProps
}: PropsWithChildren<TreeItemDraggableProps<TItem>>) => {
	const { item, indentPerLevel, currentLevel, onExpand, onCollapse } = baseProps;
	const { parentId } = path[currentLevel] as TreeSourcePosition;

	const [draggableState, setDraggableState] = useState<LocalState>(idle);
	const [dropIndicatorInstruction, setDropIndicatorInstruction] = useState<Instruction | null>(
		null,
	);

	const isDragDisabled =
		!isDragEnabled || (typeof isDragEnabled === 'function' && !isDragEnabled(item));

	const ref = useRef<RefHandler>(null);
	const cancelDelayedFn = useRef<null | (() => void)>(null);

	const intl = useIntl();

	const clearParentOfState = useCallback(() => {
		setDraggableState((current) => (current.type === 'parent-of-instruction' ? idle : current));
	}, []);

	const makeDraggable = useCallback(
		(el: HTMLElement) =>
			draggable({
				element: el,
				onGenerateDragPreview: ({ source, nativeSetDragImage }) => {
					scrollJustEnoughIntoView({ element: source.element });
					setCustomNativeDragPreview({
						getOffset: pointerOutsideOfPreview({ x: '16px', y: '8px' }),
						render({ container }) {
							// this will cause a sync re-render to add a portal
							setDraggableState({ type: 'generate-preview', container });
						},
						nativeSetDragImage,
					});
				},
				onDragStart: () => {
					/**
					 * - If item is expanded, we will collapse the item when the drag starts: an item cannot drag and drop on itself
					 * - We make this visual change onDragStart instead of onGenerateDragPreview in order to keep the ref
					 *   in a stable position. Otherwise, the collapse can falsely trigger a drop near the bottom of a scrollable container.
					 */
					if (item.isExpanded) {
						onCollapse?.(item.id);
					}
					setDraggableState({ type: 'dragging' });
					onDragStart?.();
				},
				getInitialData: () => ({
					id: item.id,
					isExpandedBeforeDrag: item.isExpanded,
					type: itemType,
				}),

				onDrop: ({ location, source }) => {
					setDraggableState(idle);
					if (location?.current?.dropTargets[0]) {
						const { data: target } = location.current.dropTargets[0];
						const instruction: Instruction | null = extractInstruction(target);

						if (
							!target ||
							!target.id ||
							target.type !== itemType ||
							!instruction ||
							// only allow an item to drop on itself if it's being reparented
							(target.id === item.id && instruction.type !== 'reparent')
						) {
							return;
						}

						/**
						 * If the instruction was blocked, calculate the move for the *desired* instruction.
						 * Ultimately, we call onDragEnd with the desired move, allowing consumers to handle the move even if it's blocked.
						 */
						const desiredInstruction =
							instruction.type === 'instruction-blocked' ? instruction.desired : instruction;

						let destinationPosition: TreeDestinationPosition;
						switch (desiredInstruction.type) {
							case 'reorder-above':
								destinationPosition = {
									parentId: target.parentId as ItemId,
									index: target.index as number,
								};
								break;
							case 'reorder-below':
								destinationPosition = {
									parentId: target.parentId as ItemId,
									index: (target.index as number) + 1,
								};
								break;
							case 'make-child':
								/**
								 * For now, we treat this instruction as a "combine" action:
								 * How to do a "combine" action:
								 *  1. Set the index to undefined.
								 *  2. Page tree will call the movePageAppend mutation, adding it to the END of the list.
								 *
								 * There is no movePagePrepend mutation at the moment. So, in order to move an item to the beginning
								 * (position 0) of a list, we have to know the id of the page that currently occupies position 0 and call
								 * movePageBefore. In the case of an unexpanded parent, we do not know the id of the page in position 0.
								 */
								destinationPosition = {
									parentId: target.id as string,
									index: undefined,
								};
								break;
							case 'reparent':
								/**
								 * 1. By using the desired level from the instruction, we can find the position of the target's ancestor.
								 * 2. Increment the index to place the item after the target's ancestor.
								 */
								const destinationSibling = (target.path as TreeSourcePosition[])[
									desiredInstruction.desiredLevel
								];
								destinationPosition = {
									parentId: destinationSibling.parentId,
									index: destinationSibling.index + 1,
								};
								break;
						}
						// Move item down within same parent. Can happen during reordering or reparenting.
						if (
							parentId === destinationPosition.parentId &&
							typeof destinationPosition.index === 'number' &&
							index < destinationPosition.index
						) {
							destinationPosition.index--;
						}

						const sourcePosition = {
							parentId,
							index,
						};
						onDragEnd(sourcePosition, destinationPosition, instruction.type);
					}
					// we want to re-expand the item when dropped if it was expanded when the drag started
					if (source.data.isExpandedBeforeDrag) {
						onExpand?.(item.id);
					}
				},
			}),
		[onDragStart, onDragEnd, item, index, parentId, onExpand, onCollapse, itemType],
	);

	const makeDropTarget = useCallback(
		(el: HTMLElement) =>
			dropTargetForElements({
				element: el,
				canDrop: ({ source }) => {
					return source.data.type === itemType;
				},
				getData: ({ input, element }) => {
					const data = {
						id: item.id,
						type: itemType,
						parentId,
						index,
						hasChildren: item.hasChildren,
						path,
					};
					return attachInstruction(data, {
						input,
						element,
						currentLevel,
						indentPerLevel,
						mode: getItemMode<TItem>(isLastInGroup, item),
						block: item.blockedInstructions,
					});
				},
				onDrag: ({ self, source, location }) => {
					const instruction = extractInstruction(self.data);
					if (
						// only show the drag indicator when dragging over the item being dragged in the case of reparenting
						(source.data.id !== item.id || instruction?.type === 'reparent') &&
						location?.current?.dropTargets[0]?.data?.id === item.id
					) {
						// special condition for empty folders to not show the drop indicator before the 'empty' text
						if (
							item.data.type === 'folder' &&
							!item.hasChildren &&
							item.isExpanded &&
							instruction?.type === 'reorder-below'
						) {
							setDropIndicatorInstruction(null);
						} else {
							if (item.hasChildren) {
								if (
									instruction?.type === 'make-child' &&
									!item.isExpanded &&
									!cancelDelayedFn.current
								) {
									cancelDelayedFn.current = delay({
										time: 500,
										fn: () => {
											onExpand?.(item.id);
											// set `cancelDelayedFn` to `null` so we know there is no delayed fn running
											cancelDelayedFn.current = null;
										},
									});
								} else if (instruction?.type !== 'make-child') {
									cancelDelayedFn.current?.();
									cancelDelayedFn.current = null;
								}
							}
							setDropIndicatorInstruction(instruction);
						}
					} else {
						setDropIndicatorInstruction(null);
					}
				},
				onDragLeave: () => {
					cancelDelayedFn.current?.();
					cancelDelayedFn.current = null;
					setDropIndicatorInstruction(null);
				},
				onDrop: () => {
					cancelDelayedFn.current?.();
					cancelDelayedFn.current = null;
					setDropIndicatorInstruction(null);
				},
			}),
		[path, item, index, currentLevel, indentPerLevel, isLastInGroup, parentId, onExpand, itemType],
	);

	const isParentOfInstruction = useCallback(
		(location: DragLocationHistory) => {
			const target = location.current.dropTargets[0];
			if (!target) {
				return false;
			}
			const instruction = extractInstruction(target.data);

			if (!instruction) {
				return false;
			}

			const targetId = target.data.id;
			invariant(typeof targetId === 'string');

			// Dragging over the item being dragged
			if (targetId === item.id) {
				return false;
			}

			const parentLevel: number = getParentLevelOfInstruction(instruction);
			return currentLevel === parentLevel;
		},
		[item.id, currentLevel],
	);

	/**
	 * We highlight the parent of a drag operation instruction to help make it clearer what level the instruction applies to.
	 * We are making the `container li` a `dropTarget` so that it picks up any drag activity for itself AND all of its children
	 */
	const makeParentOfDropTarget = useCallback(
		(el: HTMLElement) => {
			function onUpdate({ location }: { location: DragLocationHistory }) {
				if (isParentOfInstruction(location)) {
					setDraggableState(parentOfInstruction);
					return;
				}
				clearParentOfState();
			}

			return dropTargetForElements({
				element: el,
				onDragStart: onUpdate,
				onDragEnter: onUpdate,
				onDrag: onUpdate,
				onDrop() {
					clearParentOfState();
				},
				onDragLeave() {
					clearParentOfState();
				},
			});
		},
		[isParentOfInstruction, clearParentOfState],
	);

	useEffect(() => {
		const draggableEl = ref.current?.draggableDivRef.current;
		invariant(draggableEl);
		const containerEl = ref.current?.containerLiRef.current;
		invariant(containerEl);

		const dropTarget = combine(makeDropTarget(draggableEl), makeParentOfDropTarget(containerEl));
		if (isDragDisabled) {
			return dropTarget;
		}

		return combine(makeDraggable(draggableEl), dropTarget);
	}, [isDragDisabled, makeDraggable, makeDropTarget, makeParentOfDropTarget]);

	useEffect(() => {
		return () => cancelDelayedFn.current?.();
	}, []);

	const dropIndicator = dropIndicatorInstruction ? (
		<DropIndicator instruction={dropIndicatorInstruction} />
	) : null;

	return (
		<>
			<TreeItemBase<TItem>
				ref={ref}
				dragHandleProps={{
					draggable: false,
				}}
				ariaLabel={intl.formatMessage(i18n.draggableItemLabel, {
					title: item.data.title,
				})}
				dropIndicator={dropIndicator}
				draggableState={draggableState.type}
				renderItem={renderItem}
				{...baseProps}
			/>

			{draggableState.type === 'generate-preview'
				? ReactDOM.createPortal(
						<>
							{dragPreview?.(item) ||
								renderItem({
									item,
									draggableState: 'generate-preview',
								})}
						</>,
						draggableState.container,
					)
				: null}
		</>
	);
};

// Casting memoized `TreeItemDraggable` as the un-memoized `TreeItemDraggableInner`
// This is done to make types work better with `Loadable`
export const TreeItemDraggable = memo(TreeItemDraggableInner) as typeof TreeItemDraggableInner;
