import React, { forwardRef, useRef, useImperativeHandle, memo } from 'react';
import type { RefObject, ReactElement, PropsWithChildren } from 'react';
import { styled } from '@compiled/react';

import type { DragHandleProps, DraggableState, ItemId, RenderItem, TreeItem } from './tree-types';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TreeListItemLi = styled.li({
	marginTop: '0',
	marginLeft: '0',
	paddingLeft: '0',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DraggableDiv = styled.div({
	position: 'relative', // needed for the drop indicator
});

export type TreeItemBaseProps<TItem extends TreeItem> = {
	item: TItem;
	renderItem: RenderItem<TItem>;
	indentPerLevel: number;
	currentLevel: number;
	isExpanded?: boolean;
	onExpand?(itemId: ItemId): void;
	onCollapse?(itemId: ItemId): void;
	ariaLabel?: string;
	dropIndicator?: ReactElement | null;
	dragHandleProps?: DragHandleProps;
	draggableState?: DraggableState;
};

const TreeItemBaseInner = <TItem extends TreeItem>(
	{
		children,
		item,
		renderItem,
		indentPerLevel,
		currentLevel,
		isExpanded,
		onExpand,
		onCollapse,
		ariaLabel,
		dropIndicator,
		dragHandleProps,
		draggableState,
	}: PropsWithChildren<TreeItemBaseProps<TItem>>,
	ref,
) => {
	const containerLiRef = useRef<HTMLLIElement>(null);
	const draggableDivRef = useRef<HTMLDivElement>(null);
	useImperativeHandle(
		ref,
		(): RefHandler => ({
			containerLiRef,
			draggableDivRef,
		}),
	);

	const indent = indentPerLevel * currentLevel;

	return (
		<TreeListItemLi
			ref={containerLiRef}
			role="treeitem"
			{...((item.hasChildren || item.data.type === 'folder') && {
				'aria-expanded': item.isExpanded,
			})}
		>
			<DraggableDiv ref={draggableDivRef} aria-label={ariaLabel}>
				{renderItem({
					item,
					indent,
					onExpand,
					onCollapse,
					draggableState,
					dragHandleProps,
				})}
				{dropIndicator}
			</DraggableDiv>
			{isExpanded ? children : null}
		</TreeListItemLi>
	);
};

export type RefHandler = {
	containerLiRef: RefObject<HTMLLIElement>;
	draggableDivRef: RefObject<HTMLDivElement>;
};

export const TreeItemBase = memo(forwardRef(TreeItemBaseInner)) as <TItem extends TreeItem>(
	props: PropsWithChildren<TreeItemBaseProps<TItem>> & {
		ref?: RefObject<RefHandler>;
	},
) => ReactElement;
